import React from 'react';
import classes from './styles.module.scss';
import Layout from '../../components/Layout';
import PageNotFoundSVG from '@/public/assets/404.svg';
import SKButton from '../../components/UI/SKButton';
import { HOME_PAGE } from '../../constants/route.const';
import { Box, Heading } from '@chakra-ui/react';
import { useRouter } from 'next/router';

export default function Error404() {
  const router = useRouter();
  return (
    <Layout>
      <Box className={classes.PageNotFound} mt={8}>
        <PageNotFoundSVG />
        <Heading mt={18}>Page Not Found</Heading>
        <Heading as="h3" fontSize={'16px'} fontWeight={400}>
          The Page you are looking for cannot be found.
        </Heading>
        <SKButton onClick={() => router.push(HOME_PAGE)} color="white">
          Back to Homepage
        </SKButton>
      </Box>
    </Layout>
  );
}
